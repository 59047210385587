import React, { Suspense, lazy, useEffect, useState } from "react";

// Libraries
import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import retina from "retinajs";
import { Analytics } from '@vercel/analytics/react';
import CookieBot from 'react-cookiebot/lib/CookieBot';


// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";

// Home
const InteriorDesignPage = lazy(() => import("./Pages/InteriorDesign"));
// Privacy
const Privacy = lazy(() => import("./Pages/Privacy"));
const Cookies = lazy(() => import("./Pages/Cookies"));
const Advise = lazy(() => import("./Pages/Advise"));
// Blogs Types
const BlogGalleryPostPage = lazy(() => import("./Pages/BlogGalleryPostPage"));

// Portfolio
const PortfolioBorderedThreeColPage = lazy(() => import("./Pages/Portfolios/PortfolioBordered/PortfolioBorderedThreeColumn"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <CookieBot domainGroupId='24fe5325-1ce3-411b-b863-6652fc8202d6' language='ES' />
      
      <Analytics/>
      <div id="App" className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<InteriorDesignPage style={{ "--base-color": "#000000" }} />} />
                  <Route path="/privacy" element={<Privacy style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/cookies" element={<Cookies style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/advise" element={<Advise style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="/portfolio" element={<PortfolioBorderedThreeColPage style={{ "--base-color": "#0038e3" }} />} />
                  
                  {/* Blogs Types */}
                  <Route path="proyect">
                    <Route path=":id" element={<BlogGalleryPostPage style={{ "--base-color": "#0038e3" }} />} />
                  </Route>
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;